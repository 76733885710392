const getElementChild = (element: HTMLElement, depth: number): boolean => {
  const children = element.children;
  const ctrlClicked = sessionStorage.getItem('ctrlDown');
  if (ctrlClicked === 'true') {
    if (children.length === 0) {
      return true;
    }

    let count = 1;
    let index = 0;
    const stack = [children[0]];

    while (stack.length > 0) {
      const el = stack.pop();
      if (el && el.children.length > 0) {
        count++;
        break;
      }

      index++;
      if (children[index]) stack.push(children[1]);
    }

    return count < 2;
  }

  if (depth === 0) {
    return false;
  }

  for (let i = 0; i < Math.min(depth, children.length); i++) {
    const child = children[i];

    if (
      child.nodeType === Node.ELEMENT_NODE &&
      ((child as HTMLElement).tagName === 'IMG' ||
        (child as HTMLElement).style.backgroundImage !== '')
    ) {
      return false;
    }

    if (
      (child.nodeType === Node.ELEMENT_NODE &&
        (child as HTMLElement).innerHTML.trim() === '') ||
      (child.nodeType === Node.TEXT_NODE && child.nodeValue?.trim() === '')
    ) {
      return true;
    }

    if (getElementChild(child as HTMLElement, depth - 1)) {
      return true;
    }
  }
  return false;
};

export default getElementChild;
