const extractClassFromString = (tagString: string) => {
  const match = tagString.match(/class\s*=\s*["']([^"']+)["']/);
  return match ? `.${match[1].split(' ')[0]}` : null;
};

const extractTagName = (tagString: string) => {
  const match = tagString.match(/^\s*([a-zA-Z0-9-]+)\b/);
  return match ? match[1] : null;
};

const trimToHeader = (str: string) => {
  const index = str.indexOf('footer');
  return index !== -1 ? str.substring(index) : str;
};

const trimToMain = (str: string) => {
  const index = str.indexOf('main');
  return index !== -1 ? str.substring(index) : str;
};

const trimToFooter = (str: string) => {
  const index = str.indexOf('header');
  return index !== -1 ? str.substring(index) : str;
};

const cleanedPath = (xpath: string) => {
  const pathArr = xpath.split(' > ');
  const newPath = pathArr
    .map((p) => {
      const tagName = extractTagName(p);
      const tagClass = extractClassFromString(p);
      return `${tagName}${tagClass ? tagClass : ''}`;
    })
    .join(' > ');

  if (newPath.includes('header')) {
    return trimToHeader(newPath);
  }

  if (newPath.includes('main')) {
    return trimToMain(newPath);
  }

  if (newPath.includes('footer')) {
    return trimToFooter(newPath);
  }

  return newPath;
};

const getElementByPath = (path: string, doc: Document): Element => {
  const element = doc.querySelector(path);
  if (element) return element;

  const customElementPath = doc.querySelector(cleanedPath(path));
  if (customElementPath) return customElementPath;

  const pathArr = path.includes('>') ? path.split(' > ') : path.split(' ');
  const lastElementInPath = doc.querySelectorAll(pathArr[pathArr.length - 1]);

  if (lastElementInPath.length === 1) {
    return lastElementInPath[0];
  } else {
    let currentElement = doc.body;

    for (const selectorPart of pathArr) {
      const currentChange = currentElement.querySelector(
        selectorPart
      ) as HTMLElement;

      if (currentChange) {
        currentElement = currentChange;
      }
    }

    return currentElement;
  }
};

export default getElementByPath;
