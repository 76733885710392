import getElementChild from './getElementChild';

export const getTypeElement = (element: any) => {
  const tag = element.tagName.toLowerCase();
  const elementBackground = window
    .getComputedStyle(element)
    .getPropertyValue('background-image');

  if (elementBackground) {
    const hasBackgroundImage = elementBackground.includes('url');
    if (hasBackgroundImage) return 'image';
  }

  if (tag === 'img' || tag === 'svg') {
    return 'image';
  }

  if (tag === 'button' || tag === 'a' || tag === 'input') {
    return 'button';
  }

  return 'text';
};

export const checkElementsList = (byElements: string[], element: any) => {
  const tagName = element.tagName.toLowerCase();
  const argType = element.getAttribute('type');
  const isCanSelect = byElements.find(
    (item) => item === tagName || item.includes(argType)
  );
  return !!isCanSelect;
};

export const hasTextInChildren = (
  element: HTMLElement | ChildNode,
  deep = false
): boolean => {
  if (getTypeElement(element) === 'button' || deep) {
    const childNodes = element.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i];
      if (child.nodeType === Node.TEXT_NODE) {
        const textContent = child.textContent?.trim();
        if (textContent && textContent !== '') {
          return true;
        }
      }
      if (child.childNodes.length > 0) {
        return hasTextInChildren(child, true);
      }
    }
  } else {
    if (
      element.nodeType === Node.TEXT_NODE ||
      getElementChild(element as HTMLElement, 1)
    ) {
      const textContent = element.textContent?.trim();
      if (textContent && textContent !== '') {
        return true;
      }
    }
  }

  return false;
};

export const handleMouseOver = (e: any, byElements: string[] | undefined) => {
  const element = e.target;
  const checkElement = byElements && checkElementsList(byElements, element);
  if (
    element.getAttribute('selected') ||
    element.tagName?.toLowerCase() === 'html' ||
    element.tagName?.toLowerCase() === 'head' ||
    element.tagName?.toLowerCase() === 'body' ||
    element.getAttribute('marked-button') ||
    element.parentNode.getAttribute('marked-button') ||
    element.parentNode.parentNode.getAttribute('marked-button')
  ) {
    return;
  }

  const hasChildren = element.children.length > 0;
  const elementBackground = window
    .getComputedStyle(element)
    .getPropertyValue('background-image');
  const hasBackgroundImage = elementBackground.includes('url');
  const hasTextContent = hasTextInChildren(element);

  const handleOverElement = (formSelect?: any) => {
    if (formSelect) {
      formSelect.setAttribute('hovered', 'true');
      formSelect.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
    } else {
      element.setAttribute('hovered', 'true');
      element.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
    }
  };

  if (!hasChildren || hasBackgroundImage || hasTextContent) {
    if (!byElements) {
      handleOverElement();
    }
  }
  if (checkElement) {
    handleOverElement();
  }
  if (byElements && byElements.includes('form')) {
    const formInElement = element.querySelector('form');
    if (formInElement) {
      handleOverElement(formInElement);
    } else {
      const formOutElement = element.closest('form');
      if (formOutElement) {
        handleOverElement(formOutElement);
      }
    }
  }
};

export const handleMouseOut = (e: any, byElements: string[] | undefined) => {
  const element = e.target;
  const checkElement = byElements && checkElementsList(byElements, element);
  if (
    element.tagName?.toLowerCase() === 'html' ||
    element.tagName?.toLowerCase() === 'head' ||
    element.tagName?.toLowerCase() === 'body' ||
    element.getAttribute('selected')
  ) {
    return;
  }
  const preSelected = (formSelect?: any) => {
    if (formSelect) {
      if (
        !!formSelect.getAttribute('hovered') &&
        !formSelect.getAttribute('pre-select')
      ) {
        formSelect.removeAttribute('hovered');
        formSelect.style.boxShadow = null;
      }
    } else {
      if (
        !!element.getAttribute('hovered') &&
        !element.getAttribute('pre-select')
      ) {
        element.removeAttribute('hovered');
        element.style.boxShadow = null;
      }
    }
  };

  // In the presence of a list of elements that need to be monitored
  if (checkElement) {
    preSelected();
  }
  // When it is necessary to track forms on the page
  if (byElements && byElements.includes('form')) {
    const formInElement = element.querySelector('form');
    if (formInElement) {
      preSelected(formInElement);
    } else {
      const formOutElement = element.closest('form');
      preSelected(formOutElement);
    }
  }

  // When it is necessary to track all elements on the page
  if (!byElements) {
    preSelected();
  }
};
