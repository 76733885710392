import { IElement } from 'src/store/services/create-campaign/types';
import getElementByPath from './getElementByPath';
import { clickableWindow, windowTitle } from '../blocks/selected-window';

const setSelectElement = (doc: Document, params: IElement[]) => {
  params.map((item: IElement) => {
    try {
      const element = getElementByPath(item.path, doc) as HTMLElement;
      if (element) {
        const newText = windowTitle(item.name);
        const rect = element.getBoundingClientRect();
        const windowRect = clickableWindow(
          newText,
          rect.top + rect.height + window.frames[0].scrollY + 'px',
          rect.right + window.frames[0].scrollX
        );

        doc.body.appendChild(windowRect);

        element.setAttribute('selected', `${item.name}`);
        element.style.boxShadow = 'inset 0px 0px 0px 4px #FACA15';
      }
    } catch (e) {
      console.log('Select element Error(in setSelectedElements): ', e);
    }
  });
};

export default setSelectElement;
