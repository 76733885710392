import getCssSelector from 'css-selector-generator';

// @ts-ignore
window.GETCSSSELECTOR = getCssSelector;

export const getSelectedElementPath = (element: any) => {
  const path = [];
  let currentElement = element;

  if (currentElement) {
    const getElement = getCssSelector(currentElement, {
      root: window.frames[0].document.body,
      selectors: ['tag', 'id', 'class', 'attribute', 'nthchild'],
      includeTag: true,
    });

    console.log('getCssSelector', getCssSelector);
    console.log('getCssSelector from Library:', getElement);

    while (currentElement.tagName !== document.body.tagName) {
      const tagName = currentElement.tagName.toLowerCase();
      const elementId = currentElement.id
        ? `[id="${currentElement.id}"]`
        : undefined;

      const elementClasses = currentElement.className
        ? `[class="${currentElement.className}"]`
        : '';

      const currentIndex =
        Array.from(currentElement.parentNode.children).indexOf(currentElement) +
        1;

      const selector =
        currentIndex > 1
          ? `${tagName}${
              currentElement.parentNode.tagName !== document.body.tagName
                ? `:nth-child(${currentIndex})`
                : ''
            }`
          : tagName;
      path.unshift(`${selector}${elementId ? elementId : elementClasses}`);
      currentElement = currentElement.parentNode;
    }
  }

  console.log('Native path generation:', path.join(' > '));
  return path.join(' > ');
};
